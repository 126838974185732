<template>
  <div class="cps-main-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        ><i class="el-icon-s-home"></i>首页</el-breadcrumb-item
      >
      <el-breadcrumb-item>游戏管理</el-breadcrumb-item>
      <el-breadcrumb-item>游戏列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <div class="page-content">
      <el-row @keyup.enter.native="loadData(1)">
        <el-input
          v-model="queryField.gameId"
          size="small"
          placeholder="游戏ID"
        ></el-input>
        <el-input
          v-model="queryField.gameName"
          size="small"
          placeholder="游戏名称"
        ></el-input>
        <el-select
          v-model="queryField.gameStatus"
          size="small"
          placeholder="游戏状态"
        >
          <el-option
            v-for="item in optionsGameStatus"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="queryField.gameGrade"
          size="small"
          placeholder="游戏级别"
        >
          <el-option
            v-for="item in optionsGameGrade"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="queryField.gameTopic"
          size="small"
          placeholder="游戏类别"
        >
          <el-option
            v-for="item in optionsGameTopic"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="queryField.gameType"
          size="small"
          placeholder="游戏类型"
        >
          <el-option
            v-for="item in optionsGameType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="queryField.queryType"
          size="small"
          placeholder="查询类型"
        >
          <el-option label="全部" value="0"></el-option>
          <el-option label="未推广" value="1"></el-option>
          <el-option label="已推广" value="2"></el-option>
        </el-select>
        <el-autocomplete
          v-if="isSupper"
          class="inline-input"
          v-model="queryField.gameOf"
          :fetch-suggestions="querySearch"
          placeholder="游戏厂商"
          @select="handleSelect"
        ></el-autocomplete>
        <el-button
          v-if="showQuery"
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="loadData(1)"
          >查询
        </el-button>
        <el-button type="primary" @click="moveSission" size="small"
          >重置
        </el-button>
        <el-button
          v-if="showAdd"
          type="primary"
          icon="el-icon-plus"
          size="small"
          @click="toAdd"
          >新增</el-button
        >
      </el-row>
      <el-table
        class="cps-table-data"
        :data="tableData"
        size="small"
        border
        :height="height"
        style="width: 100%"
      >
        <el-table-column prop="game_id" label="游戏ID" width="60">
        </el-table-column>
        <el-table-column prop="game_name" label="游戏名称" width="120">
        </el-table-column>
        <el-table-column prop="game_status" label="游戏状态" width="80">
          <!--    采用span修改样式    原方法 :formatter="formatGameStatus"  -->
          <template slot-scope="scope">
            <span style="color: #e6a23c" v-if="scope.row.game_status === 1"
              >调试</span
            >
            <span style="color: #67c23a" v-if="scope.row.game_status === 2"
              >上线</span
            >
            <span style="color: #f56c6c" v-if="scope.row.game_status === 3"
              >下线</span
            >
          </template>
        </el-table-column>
        <el-table-column
          v-if="isSupper"
          prop="game_of"
          label="游戏厂商"
          width="80"
        >
        </el-table-column>
        <el-table-column
          prop="game_level"
          label="游戏级别"
          :formatter="formatGameGrade"
          width="80"
        >
        </el-table-column>
        <el-table-column
          prop="game_type"
          label="游戏类型"
          :formatter="formatGameType"
          width="80"
        >
        </el-table-column>
        <el-table-column
            prop="game_system"
            label="游戏系统"
            width="80"
        >
          <template slot-scope="scope">
            <img v-if="!(scope.row.game_system === 2)" src="../../assets/android.png" style="width: 22px;height: 22px;margin-right:5px">
            <img v-if="!(scope.row.game_system === 1)" src="../../assets/apple.png" style="width: 22px;height: 22px">
          </template>
        </el-table-column>
        <el-table-column prop="game_icon" label="游戏图标" width="80">
          <template slot-scope="scope">
            <el-image
              style="width: 30px; height: 30px; border-radius: 3px"
              :src="scope.row.game_icon"
              fit="cover"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column
          label="游戏app下载地址"
          width="240"
        >
          <template slot-scope="scope">
            <a v-if="scope.row.url_show === 1" style="color: #409EFF" :href="scope.row.game_app_url">{{scope.row.game_app_url}}</a>
          </template>
        </el-table-column>
        <el-table-column prop="game_url" label="游戏网页地址" width="240">
          <template slot-scope="scope">
            <a v-if="scope.row.url_show === 1" style="color: #409EFF" :href="scope.row.game_url">{{scope.row.game_url}}</a>
          </template>
        </el-table-column>
        <el-table-column label="自助首冲分成" width="130">
          <template slot-scope="scope">
            <el-input
                    :disabled="!showAuto"
                    class="cps-line-input"
                    v-model="tableData[scope.$index].auto_first"
                    size="small"
                    placeholder="自助首冲分成"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="自助续充分成" width="110">
          <template slot-scope="scope">
            <el-input
              :disabled="!showAuto"
              class="cps-line-input"
              v-model="tableData[scope.$index].auto_share"
              size="small"
              placeholder="自助分成"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
                prop="first_share"
                label="首冲分成"
                width="80"
        >
        </el-table-column>
        <el-table-column
                prop="continue_share"
                label="续充分成"
                width="80"
        >
        </el-table-column>
        <el-table-column label="操作" width="320">
          <template slot-scope="scope">
            <el-button
              v-if="showEdit"
              type="text"
              size="small"
              @click="toEdit(scope.row.game_id)"
              >编辑</el-button
            >
            <el-button
              v-if="showPackage"
              type="text"
              size="small"
              @click="toUpload(scope.row.game_id)"
              >包管理</el-button
            >
            <el-button
              v-if="showDownload"
              type="text"
              size="small"
              @click="toUploadIMG(scope.row.game_id)"
              >logo上传
            </el-button>
            <el-button
              v-if="showAuto"
              type="text"
              size="small"
              @click="save(scope.row)"
              >保存</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="toPush(scope.row.game_id)"
              >我要推广</el-button
            >
            <el-button
              v-if="showPackage"
              type="text"
              size="small"
              @click="forcePackage(scope.row.game_id)"
              >强制重分
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="cps-table-data"
        @current-change="loadData"
        background
        :page-size="15"
        :current-page="currentIndex"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>

      <el-dialog
        title="app包管理"
        :visible.sync="dialogFormVisible"
        :center="true"
        width="400px"
        :show-close="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-upload
          class="avatar-uploader"
          drag
          action=""
          :data="param"
          :http-request="httpRequest"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传apk/ipa文件，且不超过5GB
          </div>
        </el-upload>
        <el-progress
          ref="appLoadProgress"
          :percentage="param.progress"
        ></el-progress>
      </el-dialog>
      <el-dialog
        title="logo上传"
        :visible.sync="dialogFormVisible1"
        :center="true"
        width="400px"
        :show-close="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-upload
          class="avatar-uploader"
          drag
          action=""
          :data="param"
          :http-request="httpRequestIMG"
          :show-file-list="false"
          :on-success="handleAvatarSuccessIMG"
          :before-upload="beforeAvatarUploadIcon"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          <div class="el-upload__tip" slot="tip">
            只能上传png/jpg文件，且不超过2M
          </div>
        </el-upload>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  queryGameList,
  submitGameIco,
  upload,
  uploadIMG,
  getPageButtons,
  pushGame,
  updateAdminAutoShare,
  forcePackage,
  gameOfChannelDropDown, uploadFile,
} from "@/api/request";

export default {
  name: "gameList",
  props: {
    pictureUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isSupper: false,
      imgSuffix: "",
      imageUrl: "",
      dialogFormVisible: false,
      dialogFormVisible1: false,
      tableData: [],
      param: {
        gameId: "",
        progress: "",
      },

      manufacturers: "",
      restaurants: [],

      loadProgress: 0,
      queryField: {
        gameId: "",
        gameName: "",
        gameOf: "",
        gameStatus: "",
        gameType: "",
        gameTopic: "",
        gameGrade: "",
        queryType: "",
      },
      optionsGameStatus: [
        {
          value: "",
          label: "游戏状态",
        },
        {
          value: "1",
          label: "调试",
        },
        {
          value: "2",
          label: "上线",
        },
        {
          value: "3",
          label: "下线",
        },
      ],
      optionsGameGrade: [
        {
          value: "",
          label: "游戏级别",
        },
        {
          value: "1",
          label: "S级",
        },
        {
          value: "2",
          label: "A级",
        },
        {
          value: "3",
          label: "B级",
        },
        {
          value: "4",
          label: "C级",
        },
      ],
      optionsGameTopic: [
        {
          value: "",
          label: "游戏类别",
        },
        {
          value: "1",
          label: "卡牌",
        },
        {
          value: "2",
          label: "传奇",
        },
        {
          value: "3",
          label: "武侠",
        },
        {
          value: "4",
          label: "角色",
        },
        {
          value: "5",
          label: "养成",
        },
        {
          value: "6",
          label: "三国",
        },
        {
          value: "7",
          label: "奇迹",
        },
        {
          value: "8",
          label: "BT",
        },
        {
          value: "9",
          label: "休闲",
        },
        {
          value: "10",
          label: "放置",
        },
      ],
      optionsGameType: [
        {
          value: "",
          label: "游戏类型",
        },
        {
          value: "1",
          label: "H5",
        },
        {
          value: "2",
          label: "手游",
        },
      ],
      total: 0,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      height: window.innerHeight - 210,
      showQuery: false,
      showAdd: false,
      showDetail: false,
      showEdit: false,
      showPackage: false,
      showDownload: false,
      showPush: false,
      showAuto: false,
      currentIndex: Number(sessionStorage.getItem("gameCurrentIndex")),
    };
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("userInfo"));
    this.isSupper = user.groupId === "1";
    this.upGameList();
    this.loadAll();
    this.loadData(this.currentIndex);
    this.getPageButtons();
  },
  methods: {
    upGameList() {
      if (sessionStorage.getItem("gamelistStor") != null) {
        let params = JSON.parse(sessionStorage.getItem("gamelistStor"));
        (this.queryField.gameId = params.game_id),
          (this.queryField.gameName = params.game_name),
          (this.queryField.gameOf = params.game_of),
          (this.queryField.gameType = params.game_type),
          (this.queryField.gameTopic = params.game_topic),
          (this.queryField.gameStatus = params.game_status),
          (this.queryField.queryType = params.query_type),
          (this.queryField.gameGrade = params.game_grade);
      }
    },
    moveSission() {
      sessionStorage.removeItem("gamelistStor");
      this.$router.go(0);
    },
    save(row) {
      const reg = /^[01]([.][0-9]{0,4})?$/;
      if (!reg.test(row.auto_share + "")) {
        this.$message.error("自助分成格式不正确");
        return;
      }
      if (!reg.test(row.auto_first + "")) {
        this.$message.error("自助首冲分成格式不正确");
        return;
      }

      if (row.auto_share < 0 || row.auto_share > 1) {
        this.$message.error("自助分成比例应设置在0~1.0之间");
        return;
      }
      if (row.auto_first < 0 || row.auto_first > 1) {
        this.$message.error("自助首冲分成比例应设置在0~1.0之间");
        return;
      }
      let params = {
        game_id: row.game_id,
        auto_share: row.auto_share,
        auto_first: row.auto_first,
      };
      updateAdminAutoShare(params).then((res) => {
        if (res.data.success) {
          this.$message.success("配置成功");
        } else {
          this.$message.error(res.data.error.message);
        }
      });
    },
    getPageButtons() {
      let path = this.$route.path.replace("/", "");
      getPageButtons(path).then((res) => {
        if (res.data.success) {
          let menuCodes = res.data.data;
          //let menuCodes = [];
          this.showQuery = menuCodes.includes("game_list_query");
          this.showAdd = menuCodes.includes("game_list_add");
          this.showDetail = menuCodes.includes("game_list_detail");
          this.showEdit = menuCodes.includes("game_list_edit");
          this.showPackage = menuCodes.includes("game_list_package");
          this.showDownload = menuCodes.includes("game_list_download");
          this.showPush = menuCodes.includes("game_list_push");
          this.showAuto = menuCodes.includes("game_list_save");
        } else {
          this.$message.error(res.data.error.message);
        }
      });
    },
    loadData(index) {
      let params = {
        pageNo: index,
        pageSize: 15,
        params: {
          game_id: this.queryField.gameId,
          game_name: this.queryField.gameName,
          game_of: this.queryField.gameOf,
          game_type: this.queryField.gameType,
          game_topic: this.queryField.gameTopic,
          game_status: this.queryField.gameStatus,
          query_type: this.queryField.queryType,
          game_grade: this.queryField.gameGrade,
        },
      };
      //自动矫正错误页数
      let str = '^[0-9]*$';
      if(!str.search(index) || index === 0){
        params.pageNo = 1;
      }
      sessionStorage.setItem("gameCurrentIndex",params.pageNo);
      sessionStorage.setItem("gamelistStor", JSON.stringify(params.params));
      queryGameList(params).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currentIndex = index;
      });
    },
    detail(row) {
      console.log(row);
    },
    toAdd() {
      this.$router.replace("/gameList/gameAdd");
    },
    toEdit(gameId) {
      this.$router.replace(`/gameList/gameEdit/${gameId}`);
    },
    toUpload(gameId) {
      this.dialogFormVisible = true;
      this.param.gameId = gameId;
      // this.$nextTick(function () {
      //   this.param.progress = this.$refs["appLoadProgress"];
      // });
    },
    toUploadIMG(gameId) {
      this.dialogFormVisible1 = true;
      this.param.gameId = gameId;
      // this.$nextTick(function () {
      //   this.param.progress = this.$refs["iconLoadProgress"];
      // });
    },
    toPush(gameId) {
      pushGame(gameId).then((res) => {
        if (res.data.success) {
          this.$message.success(
            "操作成功，正在给你分包，一般15至30分钟，推广链接提示未成功分包请联系管理员"
          );
        } else {
          this.$message.error(res.data.error.message);
        }
      });
    },
    forcePackage(gameId) {
      this.$confirm("此操作将删除这个游戏的所有oss数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          forcePackage(gameId).then((res) => {
            if (res.data.success) {
              this.$message.success("删除成功");
            } else {
              this.$message.error(res.data.error.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消强制分包",
          });
        });
    },
    formatGameType(row, column, cellValue) {
      let ret = "";
      if (cellValue === 1) {
        ret = "H5";
      } else {
        ret = "手游";
      }
      return ret;
    },
    formatGameSystem(row, column, cellValue) {
      let ret = "";
      if (cellValue === 1) {
        ret = "安卓";
      }else if (cellValue === 2){
        ret = "IOS";
      } else{
        ret = "全部";
      }
      return ret;
    },

    formatGameStatus(row, column, cellValue) {
      let ret = "";
      if (cellValue === 1) {
        ret = "调试";
      } else if (cellValue === 2) {
        ret = "上线";
      } else {
        ret = "下线";
      }
      return ret;
    },
    formatGameGrade(row, column, cellValue) {
      let ret = "";
      if (cellValue === 1) {
        ret = "S级";
      } else if (cellValue === 2) {
        ret = "A级";
      } else if (cellValue === 3) {
        ret = "B级";
      } else {
        ret = "C级";
      }
      return ret;
    },
    // 上传文件之前的钩子
    beforeAvatarUpload(file) {
      let filename = file.name;
      let pos = filename.lastIndexOf(".");
      let suffix = "";
      if (pos != -1) suffix = filename.substring(pos);
      let isApp = ".ipa" === suffix || ".apk" === suffix;
      const isLt5G = file.size / 1024 / 1024 / 1024 < 5;
      if (!isApp) {
        this.$message.error("上传头像图片只能是 ipa或者apk 格式!");
      }
      if (!isLt5G) {
        this.$message.error("上传文件大小不能超过 5GB!");
      }
      return isApp && isLt5G;
    },
    beforeAvatarUploadIcon(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!(isJPG || isPNG)) {
        this.$message.error("上传头像图片只能是JPG或者PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      let filename = file.name;
      let pos = filename.lastIndexOf(".");
      let suffix = "";
      if (pos != -1) suffix = filename.substring(pos);
      this.imgSuffix = suffix;
      return (isJPG || isPNG) && isLt2M;
    },
    handleAvatarSuccess(file) {
      this.$message.success("文件上传成功");
      this.dialogFormVisible = false;
    },
    handleAvatarSuccessIMG(file) {
      let param = {
        game_icon: this.imgSuffix,
        game_id: this.param.gameId,
      };
      submitGameIco(param).then((res) => {
        if (res.data.success) {
          this.$message.success("logo上传成功");
          this.dialogFormVisible1 = false;
        } else {
          this.$message.error(res.data.error.message);
        }
      });
    },
    // 覆盖elemen的上传函数
    async httpRequest(item) {
      // let result = await upload(item);
      await uploadFile(item, true, param => param.name + "/" + param.shortName)
          .then(res => {
            console.log(res)
            if(!res.result) {
              this.$message.error("上传失败");
            }
          });
    },

    async httpRequestIMG(item) {
      // let result = await uploadIMG(item);
      await uploadFile(item, false,param => "image/" + param.name + "/logo")
          .then(res => {
            if(!res.result) {
              this.$message.error("上传失败");
            }
      });
    },

    querySearch(queryString, cb) {
      let restaurants = this.restaurants;
      let results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.nickName.indexOf(queryString.toLowerCase()) === 0;
      };
    },
    handleSelect(item) {
      this.queryField.game_of = item.value;
      if (item.value === "") {
        this.loadAll("");
      } else {
        this.loadAll(item.value);
      }
    },

    loadAll() {
      let me = this;
      gameOfChannelDropDown().then((res) => {
        me.restaurants = [];
        res.data.data.forEach(function (e) {
          let item = {};
          item.value = e.game_of;
          me.restaurants.push(item);
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.cps-main-page {
  .cps-table-data {
    td {
      .el-image {
        display: inherit;
      }
    }
  }
}
</style>

