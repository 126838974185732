import { render, staticRenderFns } from "./usercoin.vue?vue&type=template&id=23e30c37&scoped=true"
import script from "./usercoin.vue?vue&type=script&lang=js"
export * from "./usercoin.vue?vue&type=script&lang=js"
import style0 from "./usercoin.vue?vue&type=style&index=0&id=23e30c37&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23e30c37",
  null
  
)

export default component.exports