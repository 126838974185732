import axios from './axios';
import * as $ from 'jquery';
const ACL = "public-read";

// 登陆
export async function doLogin (params = {}) {
  return axios({
    url: `/admin/login/doLogin`,
    method: 'post',
    data: params
  })
}
//查询钱包
export async function getWallet (params = {}) {
  return axios({
    url: `/admin/getWallet`,
    method: 'get',
    data: params
  })
}
// 退出
export async function logout () {
  return axios({
    url: `/admin/login/logout`,
    method: 'post'
  })
}
//登录验证码发送
export async function sendMsg (params = {}) {
  return axios({
    url: `/admin/login/sendLoginMsg?phone=${params}`,
    method: 'get',
    data: params
  })
}
//管理员修改登录密码
export async function changePassword (params = {}) {
  return axios({
    url: `/admin/password`,
    method: 'post',
    data: params
  })
}
//管理员修改支付密码
export async function changePayPassword (params = {}) {
  return axios({
    url: `/admin/payPassword`,
    method: 'post',
    data: params
  })
}
//管理员修改支付宝账号和昵称
export async function updateZfb (params = {}) {
  return axios({
    url: `/admin/updateZfb`,
    method: 'post',
    data: params
  })
}
/*********************** User Start ****************************/
//查询用户列表
export async function queryMyUserList (params = {}) {
  return axios({
    url: `/user/getMyUser`,
    method: 'post',
    data: params
  })
}

//查询用户列表
export async function queryUserList (params = {}) {
  return axios({
    url: `/user/getUser`,
    method: 'post',
    data: params
  })
}
//新增用户
export async function addMyUser (params = {}) {
  return axios({
    url: `/user/addMyUser`,
    method: 'post',
    data: params
  })
}

//获取用户登录历史
export async function getUserLoginLog (params) {
  return axios({
    url: `/user/getLoginLogList/${params}`,
    method: 'get',
    data: params
  })
}
//获取用户余额
export async function getUserWallet (params) {
  return axios({
    url: `/user/getWalletList/${params}`,
    method: 'get',
    data: params
  })
}

//获取用户余额
export async function forbidUser (params) {
  return axios({
    url: `/user/forbidUser/${params}`,
    method: 'get',
    data: params
  })
}

//重置用户密码
export async function resetUserPwd (params) {
  return axios({
    url: `/user/resetPwd/${params}`,
    method: 'post',
    data: params
  })
}


//获取用户余额
export async function startUser (params) {
  return axios({
    url: `/user/startUser/${params}`,
    method: 'get',
    data: params
  })
}
export async function autoUser (params) {
  return axios({
    url: `/user/autoUser/${params}`,
    method: 'get',
    data: params
  })
}

export async function unAutoUser (params) {
  return axios({
    url: `/user/unAutoUser/${params}`,
    method: 'get',
    data: params
  })
}

//解绑手机
export async function unBindPhone (params) {
  return axios({
    url: `/user/unBindPhone/${params}`,
    method: 'get',
    data: params
  })
}
// 更改绑定手机
export async function upBindPhone (params) {
  return axios({
    url: `/user/upBindPhone`,
    method: 'post',
    data: params
  })
}
// 切换渠道
export async function moveUser (params) {
  return axios({
    url: `/user/moveUser`,
    method: 'post',
    data: params
  })
}

export async function exportUser (params) {
  return axios({
    url: `/user/exportUser`,
    method: 'post',
    data: params,
    responseType: 'arraybuffer'
  })
}
/*********************** User End ****************************/

/*********************** Admin Start ****************************/
//查询管理员列表
export async function adminIndexCount () {
  return axios({
    url: `/admin/adminIndexCount`,
    method: 'get'
  })
}

//查询管理员列表
export async function adminIndexCountForSuper () {
  return axios({
    url: `/admin/adminIndexCountForSuper`,
    method: 'get'
  })
}

//查询管理员列表
export async function queryAdminList (params = {}) {
  return axios({
    url: `/admin/get`,
    method: 'post',
    data: params
  })
}
//新增管理员
export async function addAdmin (params = {}) {
  return axios({
    url: `/admin/add`,
    method: 'post',
    data: params
  })
}
//检查管理员帐号
export async function checkAdminName (params = {}) {
  return axios({
    url: `/admin/check/${params.adminName}`,
    method: 'get',
    data: params
  })
}
//修改管理员信息
export async function updateAdmin (params = {}) {
  return axios({
    url: `/admin/update`,
    method: 'post',
    data: params
  })
}
//审核管理员
export async function examineAdmin (params = {}) {
  return axios({
    url: `/admin/examine`,
    method: 'post',
    data: params
  })
}
//查询管理员列表
export async function adminDetail (adminId) {
  return axios({
    url: `/admin/detail/${adminId}`,
    method: 'get'
  })
}

//查询一级渠道
export async function channelDropDown (params) {
  return axios({
    url: `/admin/queryChannel`,
    method: 'get',
    data: params
  })
}
//查询二级渠道
export async function subChannelDropDown (params) {
  return axios({
    url: `/admin/querySubChannel/${params}`,
    method: 'get',
    data: params
  })
}

//查询二级渠道
export async function gameChannelDropDown (params) {
  return axios({
    url: `/admin/queryAllChannel`,
    method: 'get',
    data: params
  })
}
//查询游戏厂商
export async function gameOfChannelDropDown () {
  return axios({
    url: `/game/queryGameOfChannel`,
    method: 'get'
  })
}


export async function channelStatistics (params = {}) {
  return axios({
    url: `/admin/channelStatistics`,
    method: 'post',
    data: params
  })
}

export async function exportStatistics (params = {}) {
  return axios({
    url: `/admin/exportStatistics`,
    method: 'post',
    data: params,
    responseType: 'arraybuffer'
  })
}

export async function active (params = {}) {
  return axios({
    url: `/admin/active`,
    method: 'post',
    data: params
  })
}

export async function day (params = {}) {
  return axios({
    url: `/admin/day`,
    method: 'post',
    data: params
  })
}

export async function channelReport (params = {}) {
  return axios({
    url: `/admin/channelReport`,
    method: 'post',
    data: params
  })
}
/*********************** Admin End ****************************/

/*********************** Group Start ****************************/
//查询用户分组列表
export async function getGroupList (params = {}) {
  return axios({
    url: `/group/get`,
    method: 'post',
    data: params
  })
}
//查询所有菜单列表,用于授权页面
export async function getMenuList (groupId) {
  return axios({
    url: `/menu/get/${groupId}`,
    method: 'post',
  })
}
//首页获取菜单
export async function initMenu () {
  return axios({
    url: `/menu/menuList`,
    method: 'post',
  })
}
//按钮授权
export async function doAuth (params = {}) {
  return axios({
    url: `/menu/auth`,
    method: 'post',
    data: params
  })
}
//获取页面按钮
export async function getPageButtons (path) {
  return axios({
    url: `/menu/getChild/${path}`,
    method: 'post',
  })
}
//新增角色
export async function addRole (params = {}) {
  return axios({
    url: `/group/add`,
    method: 'post',
    data: params
  })
}
//删除角色
export async function deleteRole (groupId) {
  return axios({
    url: `/group/delete/${groupId}`,
    method: 'post',
  })
}
/*********************** Group End ****************************/

/********************* GameConfig Start ************************/
//查询游戏提成配置列表
export async function queryGameConfigList (params = {}) {
  return axios({
    url: `/gameConfig/get`,
    method: 'post',
    data: params
  })
}
//修改游戏分成配置
export async function updateGameShare (params = {}) {
  return axios({
    url: `/gameConfig/share`,
    method: 'post',
    data: params
  })
}


/********************* GameConfig End *************************/

/******************** AdminGameConfig Start **********************/
//查询会长提成配置
export async function queryAdminGameConfigList (params = {}) {
  return axios({
    url: `/adminGameConfig/get`,
    method: 'post',
    data: params
  })
}
//修改会长提成比例
export async function updateAdminShare (params = {}) {
  return axios({
    url: `/adminGameConfig/update`,
    method: 'post',
    data: params
  })
}

export async function updateAdminAutoShare (params = {}) {
  return axios({
    url: `/adminGameConfig/updateAutoShare`,
    method: 'post',
    data: params
  })
}
//查询会长提成配置
export async function queryMyGameShareList (params = {}) {
  return axios({
    url: `/adminGameConfig/my`,
    method: 'post',
    data: params
  })
}
/********************* AdminGameConfig End ***********************/
/******************** Game Start **********************/
//查询游戏列表
export async function queryGameList (params = {}) {
  return axios({
    url: `/game/getList`,
    method: 'post',
    data: params
  })
}
//查询游戏列表
export async function queryGameRoleList (params = {}) {
  return axios({
    url: `/gameRole/list`,
    method: 'post',
    data: params
  })
}
//查询游戏详情
export async function getGame (params = {}) {
  return axios({
    url: `/game/getGameById?gameId=${params}`,
    method: 'get',
  })
}

//增加游戏
export async function addGame (params = {}) {
  return axios({
    url: `/game/addGame`,
    method: 'post',
    data: params
  })
}

//编辑游戏
export async function editGame (params = {}) {
  return axios({
    url: `/game/editGame`,
    method: 'post',
    data: params
  })
}

export async function submitGameIco (params = {}) {
  return axios({
    url: `/game/setGameIcon`,
    method: 'post',
    data: params
  })
}

export async function loadGame(params) {
  return axios({
    url: `/game/loadGameByName?gameName=${params}`,
    method: 'get',
  })
}

export async function pushGame(params) {
  return axios({
    url: `/game/pushGame?gameId=${params}`,
    method: 'get',
  })
}

export async function forcePackage(params) {
  return axios({
    url: `/game/forcePackage?gameId=${params}`,
    method: 'get',
  })
}

/********************* Game End ***********************/

/******************** OrderShareQuery Start **********************/
//提成查询
export async function queryOrderShareList (params = {}) {
  return axios({
    url: `/orderShare/get`,
    method: 'post',
    data: params
  })
}

export async function getOrderShareCount (params = {}) {
  return axios({
    url: `/orderShare/getOrderShareCount`,
    method: 'post',
    data: params
  })
}
//我的提成查询
export async function queryMyShareList (params = {}) {
  return axios({
    url: `/orderShare/myShareList`,
    method: 'post',
    data: params
  })
}
/********************* OrderShareQuery End *************************/

/******************** OrderQuery Start **********************/
//订单查询
export async function queryOrderList (params = {}) {
  return axios({
    url: `/order/get`,
    method: 'post',
    data: params
  })
}

export async function getOrderCount (params = {}) {
  return axios({
    url: `/order/getOrderCount`,
    method: 'post',
    data: params
  })
}

//订单详情
export async function queryOrderDetail (orderId) {
  return axios({
    url: `/order/detail/${orderId}`,
    method: 'post'
  })
}
//订单重新通知
export async function orderReNotify (orderId) {
  return axios({
    url: `/order/notify/${orderId}`,
    method: 'post'
  })
}

export async function exportOrder (params = {}) {
  return axios({
    url: `/order/exportOrder`,
    method: 'post',
    data: params,
    responseType: 'arraybuffer'
  })
}
/********************* OrderQuery End *************************/
/******************** OSS Start **********************/
export async function getOssToken (gameId) {
  return axios({
    url: `/oss/getOssConfig/${gameId}`,
    method: 'get'
  })
}

export async function getOssToken2 () {
  return axios({
    url: `/oss/getOssConfig2`,
    method: 'get'
  })
}

export async function uploadToOSS (param={}, sign) {
  return axios({
    headers: {'Signature': sign},
    url: `https://qili-data.oss-cn-qingdao.aliyuncs.com`,
    method: 'post',
    data: param
  })
}


/********************* OSS End *************************/

/******************** adminRecharge Start **********************/
//根据会长id查询会长用户名
export async function findChildById (adminId) {
  return axios({
    url: `/adminRecharge/find/id/${adminId}`,
    method: 'post'
  })
}
//根据会长用户名查询会长id
export async function findChildByName (adminName) {
  return axios({
    url: `/adminRecharge/find/name/${adminName}`,
    method: 'post'
  })
}
//超管给会长充值
export async function adminRecharge (params = {}) {
  return axios({
    url: `/adminRecharge/recharge`,
    method: 'post',
    data: params
  })
}

//查询会长余额
export async function queryRechargeBalance (params = {}) {
  return axios({
    url: `/adminRecharge/balance`,
    method: 'post',
    data: params
  })
}
//超管给会长充值
export async function adminRechargeList (params = {}) {
  return axios({
    url: `/adminRecharge/log`,
    method: 'post',
    data: params
  })
}
//发币订单
export async function adminOrderList (params = {}) {
  return axios({
    url: `/adminOrder/list`,
    method: 'post',
    data: params
  })
}
export async function adminOrderAmount (params = {}) {
  return axios({
    url: `/adminOrder/getAmount`,
    method: 'post',
    data: params
  })
}
//导出Excel
export async function exportCoinOrder (params = {}) {
  return axios({
    url: `/adminOrder/exportCoinOrder`,
    method: 'post',
    data: params,
    responseType: 'arraybuffer'
  })
}

//发币订单
export async function createOrder (params = {}) {
  return axios({
    url: `/adminOrder/createOrder`,
    method: 'post',
    data: params
  })
}
//发送验证码
export async function sendRechargeCode (params = {}) {
  return axios({
    url: `/adminRecharge/sendCode`,
    method: 'post',
    data: params
  })
}
//获取下级会长
export async function findChildAdminList (params = {}) {
  return axios({
    url: `/childRecharge/findChildList`,
    method: 'post',
    data: params
  })
}
//给下级会长充值
export async function childRecharge (params = {}) {
  return axios({
    url: `/childRecharge/recharge`,
    method: 'post',
    data: params
  })
}
/********************* adminRecharge End *************************/

/********************* userRecharge Start *************************/
//根据id查询用户
export async function findUserById (id) {
  return axios({
    url: `/userRecharge/find/id/${id}`,
    method: 'post',
  })
}
//根据玩家账号查询用户
export async function findUserByUserName (userName) {
  return axios({
    url: `/userRecharge/find/name/${userName}`,
    method: 'post',
  })
}
//给玩家充值
export async function userRecharge (params = {}) {
  return axios({
    url: `/userRecharge/recharge`,
    method: 'post',
    data: params
  })
}
//查询玩家充值
export async function queryUserRecharge (params = {}) {
  return axios({
    url: `/userRecharge/balance`,
    method: 'post',
    data: params
  })
}
//折扣检查
export async function checkDiscount (params = {}) {
  return axios({
    url: `/userRecharge/checkDiscount`,
    method: 'post',
    data: params
  })
}
//玩家充值记录
export async function userRechargeList (params = {}) {
  return axios({
    url: `/userRecharge/log`,
    method: 'post',
    data: params
  })
}


/********************* userRecharge End *************************/

/********************* cashOut Start *************************/
//会长提现
export async function cashOut (amount) {
  return axios({
    url: `/cashOut/cashOut/${amount}`,
    method: 'post',
  })
}
//查询会长提现列表
export async function queryCashOutList (params = {}) {
  return axios({
    url: `/cashOut/get`,
    method: 'post',
    data: params
  })
}
//查询我的提现列表
export async function queryMyCashOutList (params = {}) {
  return axios({
    url: `/cashOut/get/my`,
    method: 'post',
    data: params
  })
}
//会长提现打款操作
export async function cashOutDoPay (cashOutId) {
  return axios({
    url: `/cashOut/doPay/${cashOutId}`,
    method: 'post',
  })
}

//查询中奖活动参与用户
export async function getJoinDrawUserList () {
  return axios({
    url: `/draw/setList`,
    method: 'post',
  })
}

//查询中奖活动参与用户
export async function getLuckyUser () {
  return axios({
    url: `/draw/do`,
    method: 'post',
  })
}



/********************* cashOut End *************************/

export const upload = (item) => {
  return new Promise((resolve, reject) => {
    let ossTokenData = {};
    //获取游戏参数 获取阿里云OSS密钥
    getOssToken(item.data.gameId).then(res => {
      if (res.data.success) {
        // oss返回数据
        ossTokenData = res.data.data
        // 上传文件名称
        let filename = item.file.name
        let pos = filename.lastIndexOf('.')
        let suffix = ''
        //获取尾缀
        if (pos != -1) suffix = filename.substring(pos)
        let formData = new FormData();
        //设置路径
        const key = ossTokenData.name+"/"+ossTokenData.shortName + suffix;
        //注意formData里append添加的键的大小写
        formData.append('name', item.file.name);
        formData.append('key', key); //存储在oss的文件路径
        formData.append('OSSAccessKeyId', ossTokenData.accessId); //accessKeyId
        formData.append('policy', ossTokenData.polity); //policy
        formData.append('signature', ossTokenData.sign); //签名
        //如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
        formData.append("file", item.file);
        formData.append('success_action_status', '200'); //成功后返回的操作码
        let url = ossTokenData.host;
        let fileUrl = ossTokenData.host + key;
        $.ajax({
          url: url,
          type: 'POST',
          data: formData,
          // async: false,
          cache: false,
          contentType: false,// 必须false才会自动加上正确的Content-Type
          processData: false,// 必须false才会避开jQuery对 formdata 的默认处理
          crossDomain: true,
          xhr:function(){
            let myXhr = $.ajaxSettings.xhr();
            if(myXhr.upload){ // check if upload property exists
              myXhr.upload.addEventListener('progress',function(e){
                let loaded = e.loaded;//已经上传大小情况
                let tot = e.total;//附件总大小
                let per = Math.floor(100*loaded/tot);  //已经上传的百分比
                console.log('附件总大小 = ' + loaded);
                console.log('已经上传大小 = ' + per);
                item.data.progress.percentage = per;
              }, false); // for handling the progress of the upload
            }
            return myXhr;
          },
          success: function (data) {
            console.log(fileUrl);
            console.log(data);
            resolve(fileUrl)
          },
          error: function (data) {
            console.log(data);
            reject(data)
          }

        });

      }

    })
  })
}

export const uploadIMG = (item) => {
  return new Promise((resolve, reject) => {
    let ossTokenData = {};
    getOssToken(item.data.gameId).then(res => {
      if (res.data.success) {
        // oss返回数据
        ossTokenData = res.data.data
        // 上传文件名称
        let filename = item.file.name
        let pos = filename.lastIndexOf('.')
        let suffix = ''
        //获取尾缀
        if (pos != -1) suffix = filename.substring(pos)
        let formData = new FormData();
        const key = "image/"+ossTokenData.name+"/logo"+suffix;
        //注意formData里append添加的键的大小写
        formData.append('name', item.file.name);
        formData.append('key', key); //存储在oss的文件路径
        formData.append('OSSAccessKeyId', ossTokenData.accessId); //accessKeyId
        formData.append('policy', ossTokenData.polity); //policy
        formData.append('signature', ossTokenData.sign); //签名
        //如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
        formData.append("file", item.file);
        formData.append('success_action_status', '200'); //成功后返回的操作码
        let url = ossTokenData.host;
        //上传路径
        let fileUrl = ossTokenData.host + key;
        $.ajax({
          url: url,
          type: 'POST',
          data: formData,
          // async: false,
          cache: false,
          contentType: false,// 必须false才会自动加上正确的Content-Type
          processData: false,// 必须false才会避开jQuery对 formdata 的默认处理
          crossDomain: true,
          xhr:function(){
            let myXhr = $.ajaxSettings.xhr();
            if(myXhr.upload){ // check if upload property exists
              myXhr.upload.addEventListener('progress',function(e){
                let loaded = e.loaded;//已经上传大小情况
                let tot = e.total;//附件总大小
                let per = Math.floor(100*loaded/tot);  //已经上传的百分比
                item.data.progress.percentage = per;
              }, false); // for handling the progress of the upload
            }
            return myXhr;
          },
          success: function (data) {
            console.log(fileUrl);
            console.log(data);
            resolve(fileUrl)
          },
          error: function (data) {
            console.log(data);
            reject(data)
          }
        });
      }
    })
  })
}

export const uploadApp = (item) => {
  return new Promise((resolve, reject) => {
    let ossTokenData = {};
    //获取游戏参数 获取阿里云OSS密钥
    getOssToken(item.data.gameId).then(res => {
      if (res.data.success) {
        // oss返回数据
        ossTokenData = res.data.data
        // 上传文件名称
        let filename = item.file.name
        let pos = filename.lastIndexOf('.')
        let suffix = ''
        //获取尾缀
        if (pos != -1) suffix = filename.substring(pos)
        let formData = new FormData();
        //设置路径
        const key = "appresource/"+ossTokenData.name+suffix;
        //注意formData里append添加的键的大小写
        formData.append('name', item.file.name);
        formData.append('key', key); //存储在oss的文件路径
        formData.append('OSSAccessKeyId', ossTokenData.accessId); //accessKeyId
        formData.append('policy', ossTokenData.polity); //policy
        formData.append('signature', ossTokenData.sign); //签名
        formData.append("file", item.file); //如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
        formData.append('success_action_status', '200'); //成功后返回的操作码
        let url = ossTokenData.host;
        let fileUrl = ossTokenData.host + key;
        sessionStorage.setItem('qlUrl',fileUrl);
        $.ajax({
          url: url,
          type: 'POST',
          data: formData,
          // async: false,
          cache: false,
          contentType: false,// 必须false才会自动加上正确的Content-Type
          processData: false,// 必须false才会避开jQuery对 formdata 的默认处理
          crossDomain: true,
          xhr:function(){
            let myXhr = $.ajaxSettings.xhr();
            if(myXhr.upload){ // check if upload property exists
              myXhr.upload.addEventListener('progress',function(e){
                let loaded = e.loaded;//已经上传大小情况
                let tot = e.total;//附件总大小
                let per = Math.floor(100*loaded/tot);  //已经上传的百分比
                console.log('附件总大小 = ' + loaded);
                console.log('已经上传大小 = ' + per);
                item.data.progress.percentage = per;
              }, false); // for handling the progress of the upload
            }
            return myXhr;
          },
          success: function (data) {
            console.log(fileUrl);
            console.log(data);
            resolve(fileUrl)
          },
          error: function (data) {
            console.log(data);
            reject(data)
          }

        });

      }

    })
  })
}


export const uploadAppIMG = (item) => {
  return new Promise((resolve, reject) => {
    let ossTokenData = {};
    getOssToken(item.data.gameId).then(res => {
      if (res.data.success) {
        // oss返回数据
        ossTokenData = res.data.data
        // 上传文件名称
        let filename = item.file.name
        let pos = filename.lastIndexOf('.')
        let suffix = ''
        //获取尾缀
        if (pos != -1) suffix = filename.substring(pos)
        let formData = new FormData();
        const key = "appresource/"+ossTokenData.name+suffix;
        //注意formData里append添加的键的大小写
        formData.append('name', item.file.name);
        formData.append('key', key); //存储在oss的文件路径
        formData.append('OSSAccessKeyId', ossTokenData.accessId); //accessKeyId
        formData.append('policy', ossTokenData.polity); //policy
        formData.append('signature', ossTokenData.sign); //签名
        formData.append("file", item.file); //如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
        formData.append('success_action_status', '200'); //成功后返回的操作码
        let url = ossTokenData.host;
        //上传路径
        let fileUrl = ossTokenData.host + key;
        sessionStorage.setItem('qlUrl',fileUrl);
        $.ajax({
          url: url,
          type: 'POST',
          data: formData,
          // async: false,
          cache: false,
          contentType: false,// 必须false才会自动加上正确的Content-Type
          processData: false,// 必须false才会避开jQuery对 formdata 的默认处理
          crossDomain: true,
          xhr:function(){
            let myXhr = $.ajaxSettings.xhr();
            if(myXhr.upload){ // check if upload property exists
              myXhr.upload.addEventListener('progress',function(e){
                let loaded = e.loaded;//已经上传大小情况
                let tot = e.total;//附件总大小
                let per = Math.floor(100*loaded/tot);  //已经上传的百分比
                item.data.progress.percentage = per;
              }, false); // for handling the progress of the upload
            }
            return myXhr;
          },
          success: function (data) {
            console.log(fileUrl);
            console.log(data);
            resolve(fileUrl)
          },
          error: function (data) {
            console.log(data);
            reject(data)
          }
        });
      }
    })
  })
}



/******************** gift Start **********************/

export async function queryGiftRecord (params = {}) {
  return axios({
    url: `/gift/record/list`,
    method: 'post',
    data: params
  })
}

export async function updateState (params = {}) {
  return axios({
    url: `/gift/record/updateState`,
    method: 'post',
    data: params
  })
}

export async function updateRoleUserReport (params = {}) {
  return axios({
    url: `/gameRole/update/roleUserReport`,
    method: 'post',
    data: params
  })
}

export async function queryContactInfo (params = {}) {
  return axios({
    url: `/gift/record/queryContactInfo`,
    method: 'post',
    data: params
  })
}

export async function queryGiftList (params = {}) {
  return axios({
    url: `/gift/list`,
    method: 'post',
    data: params
  })
}

export async function updateGameRoleService (params = {}) {
  return axios({
    url: `/gameRole/service/update`,
    method: 'post',
    data: params
  })
}

export async function addGift (params = {}) {
  return axios({
    url: `/gift/add`,
    method: 'post',
    data: params
  })
}

export async function editGift (params = {}) {
  return axios({
    url: `/gift/edit`,
    method: 'post',
    data: params
  })
}

export async function deleteGift (giftId) {
  return axios({
    url: `/gift/delete/${giftId}`,
    method: 'post',
  })
}

export async function queryGift (giftId) {
  return axios({
    url: `/gift/read/${giftId}`,
    method: 'post',
  })
}


export async function queryGiftCodeList (params = {}) {
  return axios({
    url: `/gift/code/list`,
    method: 'post',
    data: params
  })
}

export async function addGiftCode (params = {}) {
  return axios({
    url: `/gift/code/add`,
    method: 'post',
    data: params
  })
}


export async function updateGiftCodeState (params = {}) {
  return axios({
    url: `/gift/code/update/state`,
    method: 'post',
    data: params
  })
}

/******************** gift end **********************/



/******************** awardProvide Start **********************/

export async function queryAwardProvideList (params = {}) {
  return axios({
    url: `/award/provide/list`,
    method: 'post',
    data: params
  })
}

export async function updateProvide (params = {}) {
  return axios({
    url: `/award/provide/edit`,
    method: 'post',
    data: params
  })
}



/******************** awardProvide Start **********************/


export async function editDrawNum (params = {}) {
  return axios({
    url: `/draw/num/add`,
    method: 'post',
    data: params
  })
}

export async function getDrawNum (params = {}) {
  return axios({
    url: `/draw/num/getNum`,
    method: 'post',
    data: params
  })
}


/******************** ql_game_extend **********************/

export async function queryGameExtendList (params = {}) {
  return axios({
    url: `/game/extend/list`,
    method: 'post',
    data: params
  })
}

export async function queryGameExtend (params = {}) {
  return axios({
    url: `/game/extend/select`,
    method: 'post',
    data: params
  })
}

export async function insertGameExtend (params = {}) {
  return axios({
    url: `/game/extend/insert`,
    method: 'post',
    data: params
  })
}

export async function updateGameExtend (params = {}) {
  return axios({
    url: `/game/extend/update`,
    method: 'post',
    data: params
  })
}

export async function deleteGameExtend (params = {}) {
  return axios({
    url: `/game/extend/delete`,
    method: 'post',
    data: params
  })
}


export async function setUrlGameExtend (params = {}) {
  return axios({
    url: `/game/extend/set/url`,
    method: 'post',
    data: params
  })
}

export async function setImgGameExtend (params = {}) {
  return axios({
    url: `/game/extend/set/img`,
    method: 'post',
    data: params
  })
}


/******************** ql_game_extend **********************/

export async function queryGameGuideList (params = {}) {
  return axios({
    url: `/game/guide/list`,
    method: 'post',
    data: params
  })
}


//获取累充
export async function getSelfCount2 (params = {}) {
  return axios({
    url: `/order/getSelfCount2`,
    method: 'post',
    data: params
  })
}

export async function payedOrder (params = {}) {
  return axios({
    url: `/transaction/setOrderPayed?orderId=${params}`,
    method: 'get',
    data: params
  })
}

export async function completeOrder (params = {}) {
  return axios({
    url: `/transaction/setOrderComplete?orderId=${params}`,
    method: 'get',
    data: params
  })
}

export async function orderCancel (params = {}) {
  return axios({
    url: `/transaction/setOrderCancel?orderId=${params}`,
    method: 'get',
    data: params
  })
}

export async function queryTransactionOrderList (params = {}) {
  return axios({
    url: `/transaction/transactionOrderPageInfo`,
    method: 'post',
    data: params
  })
}

export async function queryTransactionGoodsList(params = {}) {
  return axios({
    url: `/transaction/goods/transactionGoodsPageInfo`,
    method: 'post',
    data: params
  })
}

export async function soldOutGoods(params = {}) {
  return axios({
    url: `/transaction/goods/setGoodsSoldOut?goodsId=${params}`,
    method: 'get',
    data: params
  })
}

/******************** ql_game_server **********************/
export async function queryServerList(params = {}){
  return axios({
    url: `/game/server/serverlist`,
    method: 'post',
    data: params
  })
}

export async function delOpenServer(param = []){
  return axios ({
    url: `/game/server/deleteOpenserver`,
    method: 'post',
    data: param
  })
}

export async function addOpenServer(params = {}){
  return axios({
    url: `/game/server/add`,
    method: 'post',
    data: params
  })
}

export async function batchAddServer(param = []){
  return axios({
    url: `/game/server/batchAdd`,
    method: 'post',
    data: param
  })
}

export async function queryContent(param = {}){
  return axios({
    url: `/content/list`,
    method: 'post',
    data: param
  })
}

export async function addContent(param = {}){
  return axios({
    url: `content/addContent`,
    method: 'post',
    data: param
  })
}

export async function getContent(contentId){
  return axios({
    url: `/content/contentDetail?contentId=${contentId}`,
    method: 'get',
  })
}

export async function saveContent(parma = {}){
  return axios({
    url: `/content/saveContent`,
    method: 'post',
    data: parma
  })
}

export function uploadContentTextImg(item){
  return new Promise((resolve,reject) => {
    let ossData = {};
    getOssData2().then(res => {
      if(res.data.success){
        ossData = res.data.data;
        AWS.config.update({
          accessKeyId: ossData.accessId,
          secretAccessKey: ossData.accessKey,
          sessionToken: ossData.sessionToken,
          signatureVersion: "v2",
          endpoint: ossData.endpoint,
        })

        // 上传文件名称
        let filename = item.file.name
        let pos = filename.lastIndexOf('.')
        let suffix = ''
        //获取尾缀
        if (pos !== -1) suffix = filename.substring(pos)
        let key = "content/" + new Date().getTime() + suffix;
        let upload = new AWS.S3.ManagedUpload({
          partSize: 500 * 1024 * 1024, //分片大小
          params: {
            Bucket: ossData.bucket, //桶
            Key: key,
            Body: item.file,
            ACL: ACL,
            ContentType: item.file.type,
            'Access-Control-Allow-Credentials': '*',
          },
        });
        upload.send((err, data) => {
          if(err){
            resolve({
              result: false,
            });
          }else {
            res.data.path = key;
            resolve({
              result: true,
              data: res.data
            });
          }
        })
      }else {
        resolve({
          result: false,
        });
      }
    })
  })
}

/*******************************upload*********************************/

export async function getOssData(gameId) {
  return axios({
    url: `/ctyunOss/getCtyunToken/${gameId}`,
    method: 'post',
  })
}

export async function getOssData2() {
  return axios({
    url: `/ctyunOss/ctyunToken`,
    method: 'post',
  })
}

/**
 *  上传文件
 * @param item 参数
 * @param isProgress 进度条进度
 * @param callback 文件存储路径
 */
export function uploadFile(item,isProgress,callback){
  return new Promise((resolve, reject) => {
    let ossData = {};
    getOssData(item.data.gameId).then(res => {
      if(res.data.success){
        ossData = res.data.data;
        let gameData = {name: ossData.name,shortName: ossData.shortName}
        let c = callback(gameData);
        AWS.config.update({
          accessKeyId: ossData.accessId,
          secretAccessKey: ossData.accessKey,
          sessionToken: ossData.sessionToken,
          signatureVersion: "v2",
          endpoint: ossData.endpoint,
        });

        // 上传文件名称
        let filename = item.file.name
        let pos = filename.lastIndexOf('.')
        let suffix = ''
        //获取尾缀
        if (pos !== -1) suffix = filename.substring(pos)
        let path = c + suffix;
        let upload = new AWS.S3.ManagedUpload({
          partSize: 100 * 1024 * 1024, //分片大小
          params: {
            Bucket: ossData.bucket, //桶
            Key: path,
            Body: item.file,
            ACL: ACL,
            ContentType: item.file.type,
            'Access-Control-Allow-Credentials': '*',
          },
        });
        if(isProgress){
          upload.on("httpUploadProgress",e => {
            let loaded = e.loaded;//已经上传大小情况
            let total = e.total;//附件总大小
            let per = Math.floor(100 * loaded / total);  //已经上传的百分比
            item.data.progress = per;
          });
        }
        upload.send((err, data) => {
          if(err){
            resolve({
              result: false,
            });
          }else {
            res.data.path = path;
            resolve({
              result: true,
              data: res.data
            });
          }
        })
      }else {
        resolve({
          result: false,
        });
      }
    })
  })
}
